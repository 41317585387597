import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Image, Placeholder } from "cloudinary-react"
var cloudinaryData = require("../../config.json")
import { toSentenceCase } from "../utils/utils"

const NuvoImage = (props) => {
  if (!cloudinaryData) throw new Error("Error retrieving config.json")
  const [isLoaded, setIsLoaded] = useState(false)

  var cloudinaryImages = cloudinaryData.images.resources
  let alt = ""
  const generateAlt = props.publicId
    .split("OSG/DEV/")[1] //split cloudinary prefix
    .replace(/[_-]/g, " ")
  alt = toSentenceCase(generateAlt)

  let width, height

  if (!props.publicId) {
    throw new Error(props.publicId + " not found!")
  }

  for (let i = 0; i < cloudinaryImages.length; i++) {
    if (props.publicId === cloudinaryImages[i].public_id) {
      if (props.useAR) {
        width = cloudinaryImages[i].width
        height = cloudinaryImages[i].height
        if (!cloudinaryImages[i].width || !cloudinaryImages[i].height)
          throw new Error(
            props.publicId + " is missing width or height data from Cloudinary."
          )
      }
      if (cloudinaryImages[i].context) {
        alt = cloudinaryImages[i].context.custom.alt
        break
      }
      break
    }
  }

  // custom height and width if not working, or not from cloudinary
  if (props.arWidth && props.arHeight) {
    width = props.arWidth
    height = props.arHeight
  }

  var quality = props.quality ?? "auto:best"

  var imageWrapperClasses = classNames(props.wrapperClassName, {
    "image-meta__wrapper": true,
  })

  var imageClasses = classNames(props.className, {
    "image-meta__image": useWrapper(width, height),
  })

  // data-ar-* width and height for debugging purposes only
  if (props.useAR && useWrapper(width, height)) {
    return (
      <div
        // data-ar-width={width}
        // data-ar-height={height}
        style={{
          position: "relative",
          height: 0,
          paddingTop: (height / width) * 100 + "%",
        }}
        className={imageWrapperClasses}
      >
        {!isLoaded && props.useSkeleton && (
          <div
            className="img-loading"
            style={{
              position: "absolute",
              inset: 0,
              display: isLoaded ? "hidden" : "block",
            }}
          ></div>
        )}
        <ImageComponent
          style={{
            visibility: isLoaded ? "visible" : "hidden",
            position: "absolute",
            top: 0,
            left: 0,
          }}
          onLoad={() => setIsLoaded(true)}
          loading="lazy"
          quality={quality}
          alt={alt}
          responsive
          {...props}
          className={imageClasses}
        >
          {props.children}
          <Placeholder type="blur" />
        </ImageComponent>
      </div>
    )
  } else {
    return (
      <ImageComponent
        quality={quality}
        alt={alt}
        responsive
        loading="lazy"
        {...props}
        className={imageClasses}
      >
        {props.children}
        <Placeholder type="blur" />
      </ImageComponent>
    )
  }
}

NuvoImage.propTypes = {
  publicId: PropTypes.string.isRequired,
  children: PropTypes.element,
  className: PropTypes.string,
}

export default NuvoImage

// Base Image Component
function ImageComponent(props) {
  return (
    <Image
      quality={props.quality}
      alt={props.alt}
      responsive
      {...props}
      className={props.className}
    >
      {props.children}
    </Image>
  )
}

ImageComponent.propTypes = {
  alt: PropTypes.string,
  quality: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.element,
}

// Helper functions

function useWrapper(width, height) {
  if (width && height) {
    return true
  }
  return false
}
